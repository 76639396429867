$color-white:               #ffffff;
$color-black:               #000000;
$color-black-two:           #202020;
$color-black-three:         #222222;
$color-black-four:          #030303;
$color-black-five:          #3a0b0b;
$color-gray:                #666666;
$color-lite-gray:           #edebea;
$color-lite-gray-two:       #282828;
$color-lite-gray-three:     #f0f0f0;
$color-red:                 #cc0000;
$color-aliceblue:           #870b0b;
$color-mediumseagreen:      #874e0b;
$color-coral:               #ff9846;
$color-brown:               #770f35;
$color-firebrick:           #c52323;
$color-vividpink:           #e5385b;
$color-maccha:              #6D8551;
$color-cheese:              #EBB34B;
$color-houjicha:            #AD7E33;
$color-muscat:              #59A144;
$color-cheese:              #F4B22C;
$color-choco:               #B06642;