.header{
    position: fixed;
    width: 100%;
    z-index: 10;
}
.header__inner{
    display: flex;
    //justify-content: flex-end;
    padding: _rem(15px,sm);
    @include mq(sm, min){
        padding: _rem(22px,md) _rem(50px,md) 0;
        .midnightHeader.nav-cover &{
            background-color: transparent;
        }
        .midnightHeader.default &,
        .midnightHeader.white &{
            background-color: rgba($color-black, .7);
        }
    }
}
.header__logo{
    display: inline-block;
    width: _rem(50px, sm);
    margin-right: auto;
    .top &{
        display: none;
    }
    @include mq(sm, min){
        width: _rem(100px, md);
    }

}
.header__menu{
    margin-left: auto;
    height: _rem(50px, sm);
    ul{
       display: none;
    }
    @include mq(sm, min){
        height: auto;
        ul{
            display: flex;
        }
    }
}
.header__item{
    margin: 0 _rem(13px);
    font-family: century-gothic, sans-serif;
    font-size: _rem(14px, md);
    color: $color-white;
    line-height: _rem(45px, md);
}
.header__item--event{
    .header__link{
        display: block;
        line-height: _rem(45px, md);
        padding: 0 _rem(50px, md);
        background-color: #030303;
    }
}
.header__link{
    color: $color-white;
    @include hover($color-white, 'opacity');
}
.header__btn{
    width: _rem(40px, sm);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: transparent;
    border: none;
    @include mq(sm, min){
        display: none;
    }
    span{
        display: block;
        height: _rem(3px, sm);
        width: _rem(25px, sm);
        transition: all 0.3s;
        background-color: $color-black;
        .midnightHeader.default &{
            background-color: $color-black;
        }
        .midnightHeader.nav-cover &,
        .midnightHeader.white &{
            background-color: $color-white;
        }
        &:first-child{
            transform: translateY(-4px);
        }
        &:last-child{
            transform: translateY(4px);
        }
    }
    &.active span{
        background-color: $color-black !important;
        &:first-child{
            transform: translateY(100%) rotate(45deg);
        }
        &:nth-of-type(2){
            opacity: 0;
        }
        &:last-child{
            transform: translateY(-100%) rotate(-45deg);
        }

    }
}
.header__sp{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    background-color: rgba($color-white, .8);
    display: flex;
    align-items: center;
    z-index: 2;
    transform: translate(100%);
    @extend %animation;
    .activemenu &{
        transform: translate(0%);
    }
}
.header__sp-menu{
    margin-left: _rem(30px, sm);
}
.header__item--sp{
    font-size: _rem(30px, sm);
    margin-bottom: _rem(10px, sm);
    @extend %animation;
    transition-delay: .5s;
    transform: translate(-10%);
    opacity: 0;
    .activemenu  &{
        opacity: 1;
        transform: translate(0);
    }
    .header__link{
        color: $color-black;
    }
}
.header__item--sns{
    border-top: 1px solid $color-black;

}
.sns{
    position: absolute;
    left: _rem(15px, sm);
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
}
.sns__item{
    display: flex;
    background-color: $color-black;
    color: $color-white;
    width: _rem(30px, sm);
    height: _rem(30px, sm);
    margin: _rem(10px, sm) 0;
    justify-content: center;
    align-items: center;
    border-radius: _rem(30px, md);
    @include mq(sm, min){
        width: _rem(50px, md);
        height: _rem(50px, md);
        border-radius: _rem(50px, md);
    }
    &:hover{
        background-color: $color-white;
        color: $color-black;
        border: 1px solid $color-black;
    }
    &--sp{
        margin-right: _rem(20px, sm);
        display: inline-flex;
        color: $color-white;
        font-size: _rem(16px, sm);
    }
}