.page-cover{
    height: _rem(190px, sm);
    background-size: content;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $color-gray;
    @include mq(sm, min){
        height: _rem(340px, md);
    }
    &--event{
        background-image: url(../img/page/cover-img_event.jpg);
    }
}
.page-cover__title{
    font-size: _rem(24px, sm);
    color: $color-white;
    @include mq(sm, min){
        font-size: _rem(48px, md);
    }
    span{
        display: inline-block;
        padding: 0 0 1rem;
        border-bottom: 3px solid $color-white;
    }
}
.page-contents{
    background-color: #f0f0f0;
}
.page-contents__inner{
    @extend %innerblock;
    padding: _rem(30px, sm) _rem(15px, sm);
    @include mq(lg, min){
        padding: _rem(30px, md) 0 _rem(60px, md);
    }
}


/*
 *
 * EVENT
 *
 */

.info-text--event{
    white-space:nowrap;
    color: #6a6565;
    @include typo__paragraph;
}

/*
 * for SP
 */
.for-sp{
    @include mq(md, min){
        display: none;
    }
}
.event-shop__title{
    font-size: _rem(20px, sm);
    white-space: nowrap;
    margin: _rem(40px, sm) 0 _rem(20px, sm);
}
.event-shop__subtitle{
    font-size: _rem(15px, sm);
    text-align: left;
    margin: _rem(30px, sm) 0 _rem(20px, sm);
}
.event-shop__info{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.event-detail{
    width: 49.5%;
    background-color: $color-white;
    margin-bottom: 1%;
    display: flex;
    flex-direction: column;
    // &--l{
    //     width: 100%;
    // }
}
%eventdetail {
    font-size: _rem(11px, sm);
    min-height: _rem(40px, sm);
    display: flex;
    justify-content: center;
    align-items: center;
}

.event-detail__title{
    background-color: $color-gray;
    color: $color-white;
    @extend %eventdetail;
    a{
        color: $color-white;
        display: block;
        width: 100%;
    }
    @include eventcategory;
}
.event-detail__text{
    color: #6a6565;
    margin: auto;
    @extend %eventdetail;
}


/*
 * for PC
 */
 $margin: 100px;
 .for-pc{
     display: none;
     @include mq(md, min){
         display: block;
     }
 }
 .event-table{
    font-family: source-han-sans-japanese,sans-serif;
    margin: _rem(30px, md) auto 0;
    table-layout: fixed;
    td,th{
        border-collapse:separate;
        border: 2px solid #f0f0f0;
        table-layout: fixed;
        font-weight: normal;
        vertical-align: middle;
        text-align: center;
    }
 }
 .event-table__title{
    font-size: _rem(16px, md);
    height: _rem(80px, md);
    &--1{
        background-color: $color-black;
        color: $color-white;
    }
    &--2{
        background-color: $color-white;
        color: $color-black;
    }
 }
 .event-table__blank{
     width: _rem(275px, md);
 }
 .event-table__subtitle{
     background-color: $color-gray;
     color: $color-white;
     font-size: _rem(12px, md);
     height: _rem(80px, md);
     width: _rem(90px, md);
     padding: 0 _rem(5px, md);

     &--1,&--2{
         width: _rem(180px, md);
         font-size: _rem(14px, md);
         //background-color: $color-white;
         //color: $color-black;
     }

 }
 .event-table__detailtitle{
    background-color: $color-gray;
    color: $color-white;
    font-size: _rem(15px, md);
    box-shadow: inset _rem(10px, md) 0 rgba($color-black, .3);
    height: _rem(100px, md);
    width: 20%;
    @include eventcategory;
 }
.event-table__detailtext{
    font-size: _rem(13px, md);
    white-space:nowrap;
    color: $color-white;
    background-color: $color-white;
    @include eventcategory;
    @extend %animation;
    &--wine,
    &--pizza{
        background-color: #bbb;
        &:hover{
            opacity: 1 !important;
        }

    }
    &--none{
        color: $color-black;
    }
    &:hover{
        opacity: .8;
    }
        a{
            display: flex;
            justify-content: center;
            align-items: center;
            color: $color-white;
            height: _rem(100px, md);
        }
 }




 /*
  *
  * NEWS
  *
  */
  .news__contents{
      background-color: $color-white;
      padding: _rem(30px, sm) _rem(15px, sm);
      text-align: left;
      @include mq(lg, min){
          max-width: 1000px;
          margin: _rem(30px, md) auto;
          padding:_rem(100px, md) _rem(200px, md);
      }
      .align-center{
          text-align: center;
          @include mq(sm, max){
            text-align: left;
          }
      }
      .bold{
          font-weight: bold;
      }
  }
  .news__date{
      font-size: _rem(14px, md);
      color: #6a6565;
  }
  .news__title{
      margin-bottom: _rem(15px, sm);
      font-size: _rem(18px, sm);
      font-family: source-han-sans-japanese,sans-serif;
      @include mq(md, min){
          font-size: _rem(26px, md);
          line-height: _rem(46px, md);
      }
  }
  .news__text{
      @include typo__paragraph;
      color: #6a6565;
      font-size: _rem(18px, md);
      line-height: _rem(36px, md);
      margin: _rem(30px, md) 0;
      .onlyPc{
          display: block;
          @include mq(sm, max){
            display: none;
          }
      }
      .onlySp{
          display: none;
          @include mq(sm, max){
            display: block;
          }
      }
  }
  .news__img{
      margin-top: _rem(15px, sm);
  }
  .news__block{
    padding-top: 2rem;
  }

  .news__link{
    color: #6a6565;
    text-decoration: underline;
    &:hover{
        opacity: .6;
    }
  }

  .padding--top{
      padding-top: 1rem;
  }
  .padding--left {
    padding-left: 1.5rem;
  }