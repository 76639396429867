@import "library/_animation";
@import "library/_colors";
@import "library/_delighters";
@import "library/_fonts";
@import "library/_mixin";
@import "library/_typo";

@import "components/_error";
@import "components/_layout";
@import "components/_header";
@import "components/_footer";
@import "components/_icon";
@import "components/_form";
@import "components/_page";

html{
    font-size: map-get($_font-size, sm);
    @include mq(md, min){
       font-size: map-get($_font-size, md);
    }
    height: 100%;
    visibility: hidden;
    &.wf-active {
      visibility: visible;
    }
}
body{
    height: 100%;
}
a{
    text-decoration: none;
    @extend %animation;
}