.pagetop{
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 10;
    .activemenu &{
        display: none;
    }
}
.pagetop__inner{
    background-color: #151413;
    background-image: getArw(white);
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: center;
    width: 42px;
    height: 42px;
    display: block;
    @extend %animation;
    &:hover{
        background-color: $color-white;
        background-image: getArw();
        border: 1px solid $color-black;
    }
}
.footer__inner{
    @extend %innerblock;
    position: relative;
    margin: auto;
    padding: _rem(50px, md) 0;
    display: flex;
    flex-direction: column-reverse;
    @include mq(sm, min){
        display: block;
        margin: auto;
        padding: _rem(50px, md) _rem(15px, md);
    }
    @include mq(lg, min){
        padding: _rem(50px, md) 0;
    }
}
.footer__logo{
    display: inline-block;
    width: _rem(105px/2, sm);
    margin: auto;
    @include mq(sm, min){
        width: _rem(105px, md);
        float: left;
    }
    img{
        width: 100%;
    }
}
.footer__menu{
    margin-bottom: _rem(25px/2, sm);
    @include mq(sm, min){
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    @include mq(md, min){
        padding: 0 _rem(100px, md);
    }
}
.footer__item{
    text-align: center;
    margin: 0 auto _rem(10px, sm);
    width: _rem(398px/2, sm);
    @include mq(sm, min){
        width: _rem(225px, md);
        margin: 0 _rem(10px, md) _rem(15px, md);
    }
}
.footer__link:hover{
    opacity: .8;
}
.footer__copyright{
    @include typo__paragraph;
    font-size: _rem(11px, md);
    color: #6a6565;
    text-align: center;
    padding-bottom: _rem(50px, md);
}