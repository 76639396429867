%typo__normal {
    font-weight: 400;
}
%typo__medium {
    font-weight: 500;
}
%typo__bold {
    font-weight: 700;
}
%typo__black {
    font-weight: 900;
}

@mixin typo__heading {
    @extend %typo__bold;
    font-family: century-gothic, sans-serif;
    letter-spacing: .18rem;
    font-size: _rem(24px, sm);
    color: $color-black;
    @include mq(lg, min){
        font-size: _rem(38px, md);
    }
}

@mixin typo__subheading {
    @extend %typo__bold;
    font-size: 1.3rem;
    letter-spacing: .16rem;
    color: $color-black-three;
}

@mixin typo__blocktitle {
    @extend %typo__bold;
    font-size: 2.8rem;
    letter-spacing: .15rem;
    color: $color-black;
}
@mixin typo__subblocktitle {
    @extend %typo__normal;
    font-family: 'NotoSansJPCustom';
    font-size: 1rem;
    letter-spacing: .15rem;
}
@mixin typo__tag {
    font-size: 1.1rem;
    letter-spacing: .13rem;
    color: $color-black-four;
}

@mixin typo__date {
    font-size: 1.1rem;
    letter-spacing: .13rem;
    color: $color-bluish-grey;
}

@mixin typo__paragraph {
    font-family: source-han-sans-japanese,sans-serif;
    font-size: 1rem;
    font-style: normal;
    letter-spacing: .06rem;
    color: $color-black-two;
}
