.activemenu{
    position: fixed;
    width: 100%;
}
.wrapper,.content{
    height: 100%;
}
.content{

}
h1,h2,h3{
    @include typo__heading;
    span{
        @extend %typo__bold;
    }
}
section{
    text-align: center;
    position: relative;
    @include typo__paragraph;
}